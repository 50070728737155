import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function HoaDirectory() {
  return (
    <UserScreenWindow label="HOA Director">
      <div className="font-bold text-blue-900 text-sm text-center mt-8">
        PPC Condo Corp Directory
      </div>
      <div className="font-bold text-gray-600 text-sm mt-4">
        PPC Condo Corp Hotline Number
      </div>
      <a href="tel:0917123456">0917 123 4567</a>

      {/* <div className="font-bold text-gray-600 text-sm mt-4">
        Tali Beach Makati Office
      </div>
      <div className="flex flex-col">
        <a href="tel:09151782646">0915 178 2646</a>
        <a href="tel:0288928015">02 8892 8015</a>
      </div> */}

      <div className="font-bold text-gray-600 text-sm mt-4">
        Admin Maintenance
      </div>
      <div className="flex flex-col">
        <a href="tel:09161085814">Engr. Arjay Elumbra 0916 108 5814</a>
        <a href="tel:09308872501">Engr. Rene Espenida 0930 887 2501</a>
      </div>

      <div className="font-bold text-gray-600 text-sm mt-4">
        Security and Safety Coordinator
      </div>
      <a href="tel:09053387440">Joevanni Cialbo 0905 338 7440</a>
    </UserScreenWindow>
  );
}

export default HoaDirectory;
