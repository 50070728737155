import React, { useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import AddTenantForm from "./Forms/AddTenantForm";
import AddResidentForm from "./Forms/AddResidentForm";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import AllResidents from "./TenantManagement/AllResidents";
import TenantDetails from "./TenantManagement/TenantDetails";
import ResidentDetails from "../Dashboard/TenantManagement/ResidentsDetails";
import AllTenants from "./TenantManagement/AllTenants";
import Modal from "./Components/Modal";
import TaliMap from "./TenantManagement/TaliMap";

function TenantManagement() {
  const [activeLink, setActiveLink] = React.useState("Owners");
  const [addMode, setAddMode] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const activeLinkStyle = "text-green-600 border-b-2 border-green-600 p-b-2";

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const links = ["Tenants", "Owners"];

  return (
    <div>
      <div className="text-blue-900 text-left font-bold">Tenant Management</div>

      <div className="flex items-center justify-between p-2 border-b-2 border-gray-300">
        <div className="flex items-center justify-center gap-4">
          {links.map((link, index) => (
            <div
              key={index}
              className={`${
                activeLink === link ? activeLinkStyle : "text-gray-500"
              } cursor-pointer`}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>

        <div className="flex gap-2">
          <button
            className="bg-blue-900 text-white rounded-lg p-2"
            onClick={() => setAddMode(true)}
          >
            Add
          </button>

          <button className="bg-blue-900 text-white rounded-lg p-2">
            Download PDF Report
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between p-2 border-2 border-gray-300 rounded-lg mt-2">
        <input
          className="border-2 px-4 py-2 rounded-lg border-gray-100 w-96"
          type="search"
          placeholder="Search by name, unit owner, or address"
          onChange={handleSearch}
        />

        <div className="flex items-center gap-2 text-sm">
          <FiFilter className="text-blue-900 text-xl" />
          Filter
        </div>
      </div>

      {activeLink === "Tenants" && <AllTenants />}
      {activeLink === "Owners" && <AllResidents />}
      {activeLink === "Map" && <TaliMap />}

      {/* //add tenant modal  */}
      {addMode && activeLink === "Tenants" && (
        <Modal>
          <div className="min-w-[900px] min-h-[500px] text-sm">
            <div className="text-right text-xs">
              <button className="underline" onClick={() => setAddMode(false)}>
                Close
              </button>
            </div>
            <AddTenantForm setAddMode={setAddMode} addMode={addMode} />
          </div>
        </Modal>
      )}

      {addMode && activeLink === "Owners" && (
        <Modal>
          <div className="min-w-[900px] min-h-[500px] text-sm">
            <div className="text-right text-xs">
              <button className="underline" onClick={() => setAddMode(false)}>
                Close
              </button>
            </div>
            <AddResidentForm setAddMode={setAddMode} addMode={addMode} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default TenantManagement;
