import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import InputBox from "../Login/InputBox";
import {
  setDocData,
  getDocDataSnap,
  addNewDoc,
} from "../../utils/firebase/Firebase.utils";
import UserScreenWindow from "./UserScreenWindow";
import { useSelector } from "react-redux";

import { storage } from "../../utils/firebase/Firebase.utils";
import { current } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "react-select";

function AddGuests() {
  const { id } = useParams();
  const [numberOfGuests, setNumberOfGuests] = React.useState(1);
  const [formData, setFormData] = React.useState([]);
  const [guestsArray, setGuestsArray] = React.useState([{}]); // Initialize with one guest object
  const [inProcess, setInProcess] = React.useState(false);
  const [linkedIdentifiers, setLinkedIdentifiers] = React.useState([]);

  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  useEffect(() => {
    const getIdentifiers = async () => {
      const data = await getDocDataSnap("users", currentUser.uid, (data) => {
        setLinkedIdentifiers(data.linkedIdentifiers);
      });
    };
    getIdentifiers();
  }, []);

  useEffect(() => {
    if (id === undefined) return;

    const fetchDetails = async () => {
      const data = await getDocDataSnap("guests", id, (data) => {
        setFormData(data);
        setGuestsArray(data.guests);
        setNumberOfGuests(data.guests.length);
      });
    };
    fetchDetails();
  }, []);

  const addGuest = () => {
    setNumberOfGuests((prev) => prev + 1);
    setGuestsArray((prev) => [...prev, {}]); // Append a new guest object
  };

  const handleChange = (e, index) => {
    const updatedGuests = guestsArray.map((guest, idx) => {
      if (idx === index) {
        return { ...guest, [e.target.name]: e.target.value };
      }
      return guest;
    });
    setGuestsArray(updatedGuests);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);

    const { date, time } = formData;
    const dataObject = {
      date,
      time,
      guests: guestsArray,
      linkedIdentifiers: formData.linkedIdentifiers || [],
      requestedBy: currentUser.email,
      status: "pending",
      requestDate: new Date().toISOString(),
    };
    if (!date || !time) {
      toast.error("Please fill all fields");
      setInProcess(false);
      return;
    }

    if (guestsArray.some((guest) => !guest.name || !guest.email)) {
      toast.error("Please fill all guest fields");
      setInProcess(false);
      return;
    }

    if (guestsArray.some((guest) => !guest.email.includes("@"))) {
      toast.error("Please enter a valid email address");
      setInProcess(false);
      return;
    }

    if (guestsArray.length < 1) {
      toast.error("Please add at least one guest");
      setInProcess(false);
      return;
    }

    if (formData.linkedIdentifiers.length < 1) {
      toast.error("Please select a unit to be visited");
      setInProcess(false);
      return;
    }

    if (id !== undefined) {
      await setDocData("guests", id, dataObject);
      toast.success("Guest Details Updated");
      setInProcess(false);
      navigate("/register-guests");
      return;
    }

    await addNewDoc("guests", dataObject);
    toast.success("Guest Registration Successful");
    setInProcess(false);
    navigate("/register-guests");
  };

  return (
    <UserScreenWindow label={"Add Guest"}>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 p-2 font-bold text-blue-900">
          Guest Registration
        </div>
        <div className="flex items-center gap-4 p-2">
          <div className="flex flex-col text-xs">
            <label>Date</label>
            <input
              className="bg-green-300 p-2"
              type="date"
              required
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
              value={formData.date}
            />
          </div>
          <div className="flex flex-col text-xs">
            <label>Time</label>
            <input
              className="bg-green-300 p-2"
              type="time"
              required
              onChange={(e) =>
                setFormData({ ...formData, time: e.target.value })
              }
              value={formData.time}
            />
          </div>
        </div>
        <div className="mb-4 text-sm">
          <Select
            options={linkedIdentifiers.map((identifier) => ({
              value: identifier,
              label: identifier,
            }))}
            isMulti
            placeholder="Select Unit to be visited"
            required
            onChange={(value) =>
              setFormData({
                ...formData,
                linkedIdentifiers: value.map((val) => val.value),
              })
            }
            value={
              formData.linkedIdentifiers &&
              formData.linkedIdentifiers.map((identifier) => ({
                value: identifier,
                label: identifier,
              }))
            }
          />
        </div>
        {Array.from({ length: numberOfGuests }).map((_, index) => (
          <div
            key={index}
            className="text-xs bg-white rounded-xl p-2 relative mt-2"
          >
            <div>Guest Number {index + 1}</div>
            <div
              className="underline absolute top-0 right-2 cursor-pointer"
              onClick={() => {
                setNumberOfGuests(numberOfGuests - 1);
                setGuestsArray(
                  guestsArray.filter((guest, idx) => idx !== index)
                );
              }}
            >
              Remove
            </div>
            <div className="mt-2 space-y-4">
              <InputBox
                label="Name"
                placeholder="Enter Name"
                name="name"
                onChange={(e) => handleChange(e, index)}
                value={guestsArray[index]?.name || ""}
                required
              />
              <InputBox
                label="Vehicle Plate Number"
                placeholder="Enter Plate Number"
                name="plateNumber"
                onChange={(e) => handleChange(e, index)}
                value={guestsArray[index]?.plateNumber || ""}
                required
              />
              <InputBox
                label="Email Address"
                placeholder="Enter Email Address"
                name="email"
                onChange={(e) => handleChange(e, index)}
                value={guestsArray[index]?.email || ""}
                required
              />
            </div>
            {/* <div className="mt-2">
              <div>Upload ID or Face Photo</div>
              <input type="file" />
            </div> */}
          </div>
        ))}
        {numberOfGuests < 12 && (
          <div className="flex items-center justify-center">
            <button
              className="bg-blue-900 text-white p-2 mt-4 w-full"
              type="button"
              onClick={addGuest}
            >
              Add Guest
            </button>
          </div>
        )}
        <div className="flex flex-col items-center justify-center">
          <button
            className="bg-green-900 text-white p-2 mt-4 w-fit mx-auto rounded-lg"
            type="submit"
            onClick={handleSubmit}
          >
            {inProcess
              ? "Processing..."
              : id !== undefined
              ? "Update Guest Details"
              : "Register Guest and Generate QR Code"}
          </button>
          <div className="px-4 py-2 text-gray-600">
            Notice: Upon Registration, your guests will receive an email with a
            link where they can upload their valid IDs.
          </div>
        </div>
      </form>
    </UserScreenWindow>
  );
}

export default AddGuests;
