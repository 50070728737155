import React from "react";

function Inventory() {
  const items = [
    {
      id: 1,
      name: "Cleaning Supplies",
      quantity: 20,
      location: "Storage Room 1",
    },
    { id: 2, name: "Light Bulbs", quantity: 50, location: "Storage Room 2" },
    { id: 3, name: "Toilet Paper", quantity: 100, location: "Storage Room 1" },
    { id: 4, name: "Paint", quantity: 15, location: "Storage Room 3" },
  ];

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
     

      {/* Main Content */}
      <div className="flex-1 p-8 bg-gray-100">
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-semibold">Inventory</h1>
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Add New Item
          </button>
        </header>

        {/* Inventory Table */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Item
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Location
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {items.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.quantity}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.location}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
