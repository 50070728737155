import React from "react";

function Announcements() {
  const links = ["All", "Announcements", "Newsletters"];
  const [activeLink, setActiveLink] = React.useState("All");

  const activeLinkStyle = "bg-green-500 text-black p-2";

  // Dummy announcements data
  const announcements = [
    {
      type: "Announcements",
      title: "Elevator Maintenance",
      description:
        "Scheduled maintenance on the elevators will take place from September 15 to September 17.",
      image: "https://via.placeholder.com/300x200?text=Elevator+Maintenance",
    },
    {
      type: "Newsletters",
      title: "Monthly Newsletter - September",
      description:
        "Catch up on all the latest happenings around the condominium for the month of September.",
      image: "https://via.placeholder.com/300x200?text=September+Newsletter",
    },
    {
      type: "Announcements",
      title: "Water Supply Interruption",
      description:
        "There will be a temporary water supply interruption on September 20 from 9 AM to 5 PM.",
      image: "https://via.placeholder.com/300x200?text=Water+Interruption",
    },
    {
      type: "Newsletters",
      title: "August Newsletter",
      description:
        "Check out what happened in the month of August in this edition of the newsletter.",
      image: "https://via.placeholder.com/300x200?text=August+Newsletter",
    },
  ];

  const filteredAnnouncements =
    activeLink === "All"
      ? announcements
      : announcements.filter((announcement) => announcement.type === activeLink);

  return (
    <div className="text-blue-900 p-4 space-y-4">
      {/* Header */}
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-2xl">Announcements and Advisory</h1>
        <button className="bg-blue-900 text-white rounded-lg px-4 py-2">
          Create
        </button>
      </div>

      {/* Filter Section */}
      <div className="flex items-center justify-between border-2 border-gray-300 p-4 rounded-lg">
        <div className="flex items-center rounded-xl border-2 border-gray-300 divide-x-2 overflow-hidden">
          {links.map((link, index) => (
            <div
              key={index}
              className={`cursor-pointer text-sm min-w-20 p-2 ${
                activeLink === link ? activeLinkStyle : "bg-white text-gray-400"
              }`}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="Search announcements"
          className="border-2 border-gray-300 px-4 py-2 w-96 rounded-lg"
        />
        <button className="ml-2">Filter</button>
      </div>

      {/* Announcements Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredAnnouncements.length === 0 ? (
          <div className="text-gray-600 col-span-full">
            No announcements to show.
          </div>
        ) : (
          filteredAnnouncements.map((announcement, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={announcement.image}
                alt={announcement.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4 space-y-2">
                <h3 className="font-bold text-xl text-blue-900">
                  {announcement.title}
                </h3>
                <p className="text-gray-600">{announcement.description}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Announcements;
