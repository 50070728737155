import React from "react";
import UserBar from "./UserBar";
import SearchIcon from "@mui/icons-material/Search";
import Logo from "../assets/ppclogo.jpg";

function Header() {
  return (
    <div
      className="bg-gray-100 h-[80px] w-screen
    flex items-center justify-around
    "
    >
      <div className=" flex items-center gap-2">
        <img src={Logo} alt="" className="w-[50px] h-[50px] rounded-full" />
        <div className="text-xs font-semibold text-gray-600">Powered by</div>
        <img
          className="w-[150px]"
          src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
          alt=""
        />
      </div>
      <div className="relative flex items-center">
        <input
          type="text"
          placeholder="Search for items"
          className="bg-gray-200 rounded-lg p-2 w-[450px]"
        />
      </div>
      <div>
        <UserBar />
      </div>
    </div>
  );
}

export default Header;
