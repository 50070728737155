import React from "react";
import InputBox from "./InputBox";
import ButtonBlue from "./ButtonBlue";
import { useEffect, useState } from "react";
import {
  signInWithEmail,
  findUserDocbyId,
} from "../../utils/firebase/Firebase.utils";
import Logo from "../assets/ppclogo.jpg";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadUser } from "../../store/userSlice";

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);
  const Navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const signIn = async () => {
      try {
        setIsLoading(true);
        const res = await signInWithEmail(formData.email, formData.password);
        if (res) {
          const { email, uid } = res.user;

          const { displayName, roles, department } = await findUserDocbyId(
            res.user.uid
          );

          dispatch(loadUser({ email, displayName, uid, roles, department }));
          Navigate("/dashboard");
        }
        setIsLoading(false);
      } catch (error) {}
      setIsProcessing(false);
    };
    signIn();
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 w-[350px]">
      <div className="flex flex-col items-start justify-around gap-4">
        <div className="text-lg font-bold uppercase w-full text-center">
          <img src={Logo} alt="" className="w-20 h-20 m-auto" />
          <div className="text-gray-600 font-bold text-xs capitalize">
            Powered by
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1713873813/elegho/Elegho-Logo_vah1pv.png"
              alt=""
              className="w-16 m-auto"
            />
          </div>
        </div>
        <div className="text-center w-full">
          Enter Your Log in Credentials Below
        </div>
        <div className="w-full">
          <form className="w-full" onSubmit={handleSubmit}>
            <InputBox
              label="Email Address"
              type="email"
              placeholder="Enter your email address"
              onChange={handleChange}
              name={"email"}
            />
            <InputBox
              label="Password"
              type="password"
              placeholder="Enter your password"
              onChange={handleChange}
              name={"password"}
            />
            <div className="mt-8">
              <ButtonBlue label={isProcessing ? "Logging in..." : "Login"} />
            </div>
            <div>
              <Link to="/resetpassword" className="text-blue-600">
                Forgot Password?
              </Link>
            </div>
            <div>
              Don't have an account?{" "}
              <Link to="/signup" className="text-blue-600">
                Sign up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
