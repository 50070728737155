import React, { useState } from "react";

function Maintenance() {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      task: "Elevator Maintenance",
      date: "2024-09-20",
      cost: "Php500,000",
      provider: "ABC Elevator Services",
    },
    {
      id: 2,
      task: "Genset Inspection",
      date: "2024-09-22",
      cost: "Php30,000",
      provider: "XYZ Power Systems",
    },
  ]);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
    

      {/* Main Content */}
      <div className="flex-1 p-8 bg-gray-100">
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-xl font-semibold">Maintenance Schedule</h1>
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Add New Task
          </button>
        </header>

        {/* Maintenance Form Section */}
        <div className="bg-white shadow rounded-lg p-6 mb-8">
          <form>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-1">
                <label className="block text-gray-700">Task</label>
                <input
                  type="text"
                  placeholder="e.g., Elevator Maintenance"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-1">
                <label className="block text-gray-700">Cost</label>
                <input
                  type="text"
                  placeholder="e.g., Php 500,000"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-1">
                <label className="block text-gray-700">Service Provider</label>
                <select className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md">
                  <option>ABC Elevator Services</option>
                  <option>XYZ Power Systems</option>
                  <option>123 Plumbing</option>
                  <option>Custom Provider</option>
                </select>
              </div>
              <div className="col-span-1">
                <label className="block text-gray-700">Date</label>
                <input
                  type="date"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="col-span-1">
                <button className="mt-6 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                  Schedule Task
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Calendar Section */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Scheduled Maintenance</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Task
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cost
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Service Provider
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tasks.map((task) => (
                  <tr key={task.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{task.task}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{task.date}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{task.cost}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {task.provider}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
