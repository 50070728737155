import React from "react";
import UserScreenWindow from "./UserScreenWindow";

function SOS() {
  return (
    <UserScreenWindow label="Emergency Information">
      <div className="bg-white rounded-xl p-2 mt-4">
        <div className="text-center text-sm font-bold text-blue-900 mt-8">
          Emergency Hotlines
        </div>
        <div className="font-bold text-center text-green-800">
          Tap the numbers below to call
        </div>

        <div className="text-sm font-bold text-gray-600 mt-8">
          PNP Makati Hotline
        </div>
        <div className="flex flex-col">
          <a href="tel:09985987920">0998-598-7920</a>
          <a href="tel:09159191361">0915-919-1361</a>
        </div>

        <div className="text-sm font-bold text-gray-600 mt-8">
          Makati Fire Department
        </div>
        <a href="tel:0288185150">0288185150</a>

        {/* <div className="text-sm font-bold text-gray-600 mt-8">
          Bureau of Fire Protection - Nasugbu
        </div>
        <div className="flex flex-col">
          <a href="tel:0434160125">043 416 0125</a>
          <a href="tel:09954465073">0995 446 5073</a>
        </div> */}

        <div className="text-sm font-bold text-gray-600 mt-8">
          Meralco Hotline
        </div>
        <a href="tel:0216211">02-16211</a>

        <div className="text-sm font-bold text-gray-600 mt-8">
          Makati Medical Center
        </div>
        <div className="flex flex-col">
          <a href="tel:0288888910">+632 8888 8910</a>
        </div>
      </div>
    </UserScreenWindow>
  );
}

export default SOS;
